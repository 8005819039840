import { first, last } from 'lodash';
import { GetServerSideProps } from 'next';
import React from 'react';
import {
  inspifySiteId,
  isLoubiAirways,
  loubiAirwaysBoutiqueId,
  loubiAirwaysCnBoutiqueId,
  loubiFutureSiteId
} from '../config';
import { IStore, IVirtualBoutiqueConfig } from '../interfaces';
import { getGeolocation } from '../services/geolocation';
import logger from '../services/serverLogger';
import { getStoreById } from '../services/stores';
import { getVirtualBoutiqueConfigByStoreId } from '../services/virtualBoutique';
import { getIpAddressFromRequest } from '../utils/ipAddress';
import { VirtualBoutiqueWrapper } from './virtualboutique/[id]';

const getUrlByBrand = (brand: string, country?: string) => {
  const defaultCity = 'sg';
  switch (brand) {
    case 'piaget':
      return `https://piaget.v-boutique.com/${defaultCity}`;
    case 'louisvuitton':
      return `https://louisvuitton.v-boutique.com/${defaultCity}`;
    case 'iwc':
      return country === 'cn'
        ? 'https://iwc.inspify.cn/sihh2020'
        : `https://iwc.v-boutique.com/${defaultCity}`;
    case 'alange-soehne':
      return `https://alange-soehne.v-boutique.com/${defaultCity}`;
    case 'rogerdubuis':
      return `https://rogerdubuis.v-boutique.com/${defaultCity}`;
    case 'jaeger-lecoultre':
      return `https://jaeger-lecoultre.v-boutique.com/au/melbourne`;
    case 'zegna':
      return `https://zegna.v-boutique.com/us/nyc`;
    case 'loubiairways':
    case 'loubiairways-dev':
    case 'loubifuture':
    case 'loubifuture-test':
    case 'experience':
      return null;
    default:
      return `https://www.inspify.com`;
  }
};

const hostsWithRootPathToHub = [
  'inspify.meet-us.live',
  'inspify-dev.meet-us.live',
  'gusto-luxe.meet-us.live',
  'inspify-test.meet-us.live',
  'gusto-luxe.inspify.com',
  'meet-test.inspify.com'
];

function IndexPage({
  config,
  store
}: {
  config: IVirtualBoutiqueConfig | null;
  store: IStore | null;
}) {
  const isStoreWithRootPath = (storeId?: string) =>
    isLoubiAirways(storeId) ||
    storeId === loubiFutureSiteId ||
    storeId === inspifySiteId;
  React.useEffect(() => {
    if (config || store) {
      return;
    }
    const components = location.host.split('.');
    const brand = first(components);
    const country = last(components);
    const search = window.location.search;
    const url = getUrlByBrand(brand, country);
    if (url) {
      window.location.href = `${url}${search}`;
    }
  }, []);
  if (isStoreWithRootPath(store?.id))
    return <VirtualBoutiqueWrapper config={config} store={store} />;
  return null;
}

export const getServerSideProps: GetServerSideProps = async (context) => {
  const { req } = context;
  logger.info({ requestHeaders: req.headers }, 'Index page');
  const host = req.headers.host || '';
  const brand = host.split('.')[0];
  let config = null;
  let store = null;
  let isInChina = false;
  try {
    const ipAddress = getIpAddressFromRequest(req);
    const geolocation = await getGeolocation(ipAddress);
    isInChina = geolocation.country_name === 'China';
  } catch (e) {
    console.error('loading isInChina failed with error: ', e);
  }
  if (brand.startsWith('loubiairways')) {
    logger.info({ isInChina }, 'Loubiairways Index page');
    try {
      config = await getVirtualBoutiqueConfigByStoreId(
        isInChina ? loubiAirwaysCnBoutiqueId : loubiAirwaysBoutiqueId
      );
      store = await getStoreById(
        isInChina ? loubiAirwaysCnBoutiqueId : loubiAirwaysBoutiqueId
      );
    } catch (e) {
      console.error(e);
    }
  }
  if (brand.startsWith('loubifuture')) {
    logger.info({ isInChina }, 'Loubifuture Index page');
    try {
      config = await getVirtualBoutiqueConfigByStoreId(loubiFutureSiteId);
      store = await getStoreById(loubiFutureSiteId);
    } catch (e) {
      console.error(e);
    }
  }
  if (host === 'meet.inspify.com') {
    logger.info('Inspify SG Site Index page');
    try {
      config = await getVirtualBoutiqueConfigByStoreId(inspifySiteId);
      store = await getStoreById(inspifySiteId);
    } catch (e) {
      console.error(e);
    }
  }
  if (host === 'app.inspify.com') {
    return {
      redirect: {
        destination: 'https://meet.inspify.com/hub',
        permanent: false
      }
    };
  }
  if (hostsWithRootPathToHub.includes(host)) {
    return {
      redirect: {
        destination: '/hub',
        permanent: false
      }
    };
  }
  return {
    props: { config, store }
  };
};

export default IndexPage;
